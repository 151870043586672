import React, { useEffect, useState } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import 'lightgallery/css/lg-thumbnail.css';
import Pagination from '../Pagination';
import { paginationParams } from '../../_services/paginationParams';
import { helpers } from '../../_services/helpers';

export default function PicturesGallery({folderGallery, medias, color}) {

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const lastItemIndex = currentPage * paginationParams.mediasPerPage;
    const firstItemIndex = lastItemIndex - paginationParams.mediasPerPage;

    useEffect(() => {
        setCurrentPage(currentPage);
    }, [])

    const options = {
        buttons: {
            showDownloadButton: false
        },
        caption: {
            showCaption: false
        },
        progressBar: {
            backgroundColor: '#0c71c3'
        }
    }

    return (
        <div className='PicturesGalleryList'>
            <LightGallery
                speed={500}
                plugins={[lgThumbnail]}
                download={false}
            >
                {
                    medias
                        .map((media) => {
                            return (
                                <a href={helpers.baseUrl + '/medias/' + folderGallery + '/pictures/' + media.name} key={media.id}>
                                    <img src={helpers.baseUrl + '/medias/' + folderGallery + '/pictures/thumbs/' + media.thumb} className='PictureItem'/>
                                </a>
                            )
                        })
                        /* .slice(firstItemIndex, lastItemIndex) */
                }
            </LightGallery>
            {/* {
                medias.length > paginationParams.mediasPerPage ?
                <Pagination
                    totalItems={
                        medias.length
                        }
                    itemsPerPage={paginationParams.mediasPerPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    color={color}
                    /> : null
            } */}
        </div>
    )
}