import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { checkToken } from '../_services/checkToken';
import { userServices } from '../_services/user_services';
import { useSelector } from 'react-redux';
import { helpers } from '../_services/helpers';

export default function Footer() {

    let location = useLocation();

    const [user, setUser] = useState();
    const alertMessage = useSelector((state) => state.alertMessage);

    useEffect(() => {
        if (userServices.isConnected() & !checkToken.expired()) {
            setUser(userServices.getUser());
        } else {
            setUser(null);
        }
    }, [userServices.isConnected(), alertMessage])

    return (
        <>
            {
                user && location.pathname.slice(-22) !== 'creer-mon-mot-de-passe' &&
                    <div className='Footer'>
                        <NavLink to='/' className='LinkLogoFooter'>
                            <img src={helpers.baseUrl + '/LaBad-Logo.png'} alt='logo' className='LogoFooter'/>
                        </NavLink>
                        <span className='Copyright'>La BAD © 2022 - créé par Jean'Ouis</span>
                        <a href='https://www.facebook.com/groups/417490591779490/' target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="facebook" viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                            </svg>
                        </a>
                    </div>
            }
        </>
    )
}
