import React, { useEffect, useState } from 'react';
import { axiosCaller } from '../_services/axiosCaller';

export default function NextBirthday() {

    const [birthdays, setBirthdays] = useState();
    let birthdayTitle = '';

    useEffect(() => {
        axiosCaller.callAxios('/api/birthdays', 'GET')
        .then((response) => {
            setBirthdays(response.data);
        })
        .catch((error) => {
            console.log(error)
        })
    }, [])
    
    const nextBirthdayDisplay = () => {
        let yearToTake = new Date().getFullYear()
        let lastBirthday = '11/14/' + yearToTake;
        let memberBirth = '';
        let memberIcs = '';
        let birthInfos = [];
        if (Date.parse(new Date()) > Date.parse(lastBirthday)) {
            yearToTake++;
        }
        let nextBirthday = '12/31/'+ yearToTake;
        birthdays.forEach((birthday) => {
            let actualBirthDate = new Date((new Date(birthday.birth_date).getMonth() + 1) + '/' + new Date(birthday.birth_date).getDate() + '/' + yearToTake);
            if (Date.parse(actualBirthDate) >= Date.parse(new Date()) && Date.parse(actualBirthDate) < Date.parse(nextBirthday)) {
                nextBirthday = actualBirthDate;
                /* memberIcs = member.icsFile; */
                if (birthday.username === 'Vanessa' || birthday.username === 'Laëtitia') {
                    memberBirth = 'Les Jumelles';
                } else {
                    memberBirth = birthday.username;
                }
            }
        })
        birthInfos.push(memberBirth);
        birthInfos.push(nextBirthday.toLocaleDateString('FR-fr'));
        /* birthInfos.push(memberIcs); */
        birthInfos.push(nextBirthday);
        return birthInfos;
    }
    
    if (birthdays) {
        if(Date.parse(nextBirthdayDisplay()[2/* 3 */]) === Date.parse(new Date())) {
            birthdayTitle = 'Aujourd\'hui, Anniversaire de ';
        } else {
            birthdayTitle = 'Prochain Anniversaire ';
        };
    }
    
    return (
        <>
            {
                birthdays && 
                    <div className='BirthdayBox'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="calendar" viewBox="0 0 16 16">
                            <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                        </svg>
                        <a /* href={helpers.baseUrl + '/birthday/' + nextBirthdayDisplay()[2]} */ className='BirthLink'>
                            <div className='BirthMess'>
                                <span>{birthdayTitle}</span>
                                <span><b>{nextBirthdayDisplay()[0]} - {nextBirthdayDisplay()[1]}</b></span>
                            </div> 
                        </a>
                    </div>
            }
        </>
    )
}
