import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateAlertMessage } from '../redux/redux';
import { userServices } from '../_services/user_services';
import { checkToken } from '../_services/checkToken';
import NextBirthday from './NextBirthday';
import { helpers } from '../_services/helpers';

export default function NavBar() {

    let location = useLocation();

    const alertMessage = useSelector((state) => state.alertMessage);
    const dispatchAlertMessage = useDispatch();
    const stockAlertMessageInStore = (data) => {
        dispatchAlertMessage(updateAlertMessage(data))
    }

    const navigate = useNavigate();

    const [miniMenu, setMiniMenu] = useState(false);
    const menuRef = useRef();
    const accountRef = useRef();

    const [user, setUser] = useState();

    const username = userServices.getUsername();
    const profilePicture = userServices.getProfilePicture();
    
    useEffect(() => {
        if (userServices.isConnected() & !checkToken.expired()) {
            setUser(userServices.getUser());
            /* findUsername(userServices.getUser()); */
        } else {
            setUser(null);
        }
    }, [userServices.isConnected()])

    const displayMiniMenu = () => {
        setMiniMenu(true)
        menuRef.current.style.left = 0;
        menuRef.current.style.transition = 'left 500ms linear';
        accountRef.current.style.left = '50%';
        accountRef.current.style.transition = 'left 500ms linear';
    }

    const hideMiniMenu = (e) => {
        setMiniMenu(false)
        menuRef.current.style.left = '-100vw';
        accountRef.current.style.left = '-100vw';
        if (document.body.style.overflow === 'hidden' && e.target.id !== 'closeMiniMenu') {
            document.body.style.overflow = 'auto';
        }
    }

    const disconnect = () => {
        hideMiniMenu()
        userServices.logout()
        setUser(null);
        stockAlertMessageInStore({type: 'success', content: 'Tu as été déconnecté(e) avec succès'});
        navigate('/');
    }

    return (
        <>
        {
            user && location.pathname.slice(-22) !== 'creer-mon-mot-de-passe' &&
                <nav className='navbar'>
                    <NavLink to={user ? '/accueil' : '/'}>
                        <img src={helpers.baseUrl + '/LaBad-Logo.png'} alt='logo' className='logoNav' onClick={user ? (e) => hideMiniMenu(e) : null} />
                    </NavLink>
                    <NextBirthday />
                    {!miniMenu ? <svg onClick={(e) => displayMiniMenu(e)} xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className='miniMenuBtn' viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg> :
                        <svg onClick={(e) => hideMiniMenu(e)} id='closeMiniMenu' xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className='closeMiniMenu' viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    }
                    <ul ref={menuRef} className='menu'>
                        <li onClick={(e) => hideMiniMenu(e)} className='menuItem' activeclassname='active'>
                                <NavLink to='/accueil' className='NavItem'>
                                    Accueil
                                </NavLink>
                        </li>
                        <li onClick={(e) => hideMiniMenu(e)} className='menuItem' activeclassname='active'>
                                <NavLink to='/nos-soirees' className='NavItem'>
                                    Nos Soirées
                                </NavLink>
                        </li>
                        <li onClick={(e) => hideMiniMenu(e)} className='menuItem' activeclassname='active'>
                                <NavLink to='/nos-sorties-evenements' className='NavItem'>
                                    Nos Sorties, Evénements
                                </NavLink>
                        </li>
                        <li onClick={(e) => hideMiniMenu(e)} className='menuItem' activeclassname='active'>
                                <NavLink to='/nos-vacances' className='NavItem'>
                                    Nos Vacances
                                </NavLink>
                        </li>
                        <li onClick={(e) => hideMiniMenu(e)} className='menuItem' activeclassname='active'>
                                <NavLink to='/la-jjfc' className='NavItem'>
                                    La JJFC
                                </NavLink>
                        </li>
                        <li onClick={(e) => hideMiniMenu(e)} className='menuItem' activeclassname='active'>
                                <NavLink to='/la-jointure' className='NavItem' activeclassname='active'>
                                    La Jointure
                                </NavLink>
                        </li>
                        {/* <li className='separator'>
                            |
                        </li>
                        <li onClick={(e) => hideMiniMenu(e)} className='menuItem' activeclassname='active'>
                                <NavLink to='/tout-au-fil-du-temps' className='NavItem AllTimeNavItem'>
                                    Tout au fil du temps
                                </NavLink>
                        </li> */}
                    </ul>
                    <div ref={accountRef} className='accountBox'>
                        <div className='accountBtnAndList'>
                            <div className='accountBtn'>
                                <img src={helpers.baseUrl + "/medias/profilePictures/" + profilePicture} alt="profilePicture" className='profilePicture' />
                                <span className='username'>{username}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="chevron-down" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                            <ul className='accountList'>
                                <NavLink to='/mon-compte'>
                                    <li className='accountListItem' onClick={() => hideMiniMenu()}>
                                            Mes informations
                                    </li>
                                </NavLink>
                                <li className='accountListItem' onClick={() => disconnect()}>
                                    Me déconnecter
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            }
        </>
    )
}
