import React, { useState } from 'react';
import Login from '../components/Login/Login';
import ForgottenPwd from '../components/Login/ForgottenPwd';
import { helpers } from '../_services/helpers';

export default function LoginPage() {
    
    document.title = 'La BAD | Connexion';

    const [loginDisplayed, setLoginDisplayed] = useState(true);

    const [forgottenPwdDisplayed, setForgottenPwdDisplayed] = useState(false);

    return (
        <div className='LoginPage'>
            <img src={helpers.baseUrl + '/LaBad-Logo.png'} alt='logo' className='LogoLogin'/>
            {
                loginDisplayed &&
                <Login 
                    loginDisplayed={loginDisplayed}
                    setLoginDisplayed={setLoginDisplayed}
                    forgottenPwdDisplayed={forgottenPwdDisplayed}
                    setForgottenPwdDisplayed={setForgottenPwdDisplayed}
                />
            }
            {
                forgottenPwdDisplayed &&
                <ForgottenPwd 
                    loginDisplayed={loginDisplayed}
                    setLoginDisplayed={setLoginDisplayed}
                    forgottenPwdDisplayed={forgottenPwdDisplayed}
                    setForgottenPwdDisplayed={setForgottenPwdDisplayed}
                />
            }
        </div>
    )
}
