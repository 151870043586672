import React from 'react'

export default function CategoryTitleBand( {slugCategory} ) {

    const categories = [
        {
            slug: "nos-soirees",
            category: "Nos Soirées",
            color: "#304589"
        },
        {
            slug: "nos-sorties-evenements",
            category: "Nos Sorties, Événements",
            color: "#12876f"
        },
        {
            slug: "nos-vacances",
            category: "Nos Vacances",
            color: "#c5310d"
        },
        {
            slug: "la-jjfc",
            category: "La JJFC",
            color: "#e07f35"
        },
        {
            slug: "la-jointure",
            category: "La Jointure",
            color: "#8a8a8a"
        },
        {
            slug: "tout-au-fil-du-temps",
            category: "Tout au fil du temps",
            color: "#0c71c3"
        },
        {
            slug: "/mon-compte",
            category: "Mon Compte",
            color: "#0c71c3"
        },
        {
            slug: "/mon-compte/modifier-mes-informations",
            category: "Modifier mes informations",
            color: "#0c71c3"
        },
        {
            slug: "/mon-compte/modifier-mon-mot-de-passe",
            category: "Modifier mon mot de passe",
            color: "#0c71c3"
        },
        {
            slug: "/mot-de-passe-oublie",
            category: "Mot de passe oublié",
            color: "#0c71c3"
        },
        {
            slug: "/creer-mon-mot-de-passe",
            category: "Créer mon mot de passe",
            color: "#0c71c3"
        },
    ];

    let categoryTitle;
    let colorBg;
    let marginTop;
    categories.forEach((category) => {
        if (slugCategory === category.slug) {
            categoryTitle = category.category;
            colorBg = category.color;
        }
        if (slugCategory === '/mot-de-passe-oublie' || slugCategory === '/creer-mon-mot-de-passe') {
            marginTop = 0;
        } else {
            marginTop = '72px';
        }
    })

    return (
        <div className='CategoryTitleBand'
            style={
                {
                    background: colorBg === '' ? 'linear-gradient(45deg, rgb(46, 50, 53), #0c71c3)' : 'linear-gradient(45deg, rgb(46, 50, 53), ' + colorBg + ')',
                    marginTop: marginTop
                }
            }>
            <div className='BoxInfosBand'>
            <h2>La BAD</h2>
            <span className='Slogan'>"La Bande à Dainville, notre grande famille"</span>
            <h3>{categoryTitle === '' ? 'Page introuvable' : categoryTitle}</h3>
            </div>
        </div>
    )
}
