import React, { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateAlertMessage } from '../../redux/redux';
import { axiosCaller } from '../../_services/axiosCaller';
import { userServices } from '../../_services/user_services';
import { useState } from 'react';

export default function Login({ loginDisplayed, setLoginDisplayed, forgottenPwdDisplayed, setForgottenPwdDisplayed }) {

    const navigate = useNavigate();

    const dispatchAlertMessage = useDispatch();
    const stockAlertMessageInStore = (data) => {
        dispatchAlertMessage(updateAlertMessage(data))
    }

    useEffect(() => {
        if (userServices.isConnected()) {
            navigate('/accueil');
        }
    }, [])

    // Validation du formulaire et envoi des valeurs vers l'API
    const validForm = (e) => {
        e.preventDefault();
        let formValues = {};
        for (let item of e.target) {
            if (item.name !== '') {
                formValues[item.name] = item.value;
            }
        }
        axiosCaller.callAxiosDisconnect('/api/login', 'POST', {
            "username": formValues.email,
            "password": formValues.password
        })
        .then(response => {
            console.log(response.data)
            userServices.saveToken(response.data.token);
            axiosCaller.callAxios('/api/user/', 'GET')
            .then((response) => {
                userServices.saveUsername(response.data.username);
                userServices.saveProfilePicture(response.data.picture);
                stockAlertMessageInStore({ type: 'info', content: 'Bienvenue <b>' + response.data.username + '</b>'});
                navigate('/accueil');
            })
        })
        .catch(error => {
            if (error.response.data.message === 'Identifiants invalides.') {
                stockAlertMessageInStore({ type: 'error', content: 'Ton email et/ou ton mot de passe est incorrect.' });
            } else if (error.response.data.detail === 'App\\Entity\\User::getPassword(): Return value must be of type string, null returned') {
                stockAlertMessageInStore({ type: 'error', content: 'Ton compte est bien créé mais tu n\'as pas encore créé ton mot de passe personnel.\nRéfère toi au mail que tu as reçu de la part de LA BAD.' });
            }
        });
    }

    const [pwdDisplayed, setPwdDisplayed] = useState();

    const showPwd = () => {
        setPwdDisplayed(!pwdDisplayed);
        console.log(process.env.REACT_APP_ENV);
    }

    return (
        <div className='login'>
            <form noValidate id='loginForm' onSubmit={(e) => validForm(e)}>
                <div className='formItem'>
                    <label htmlFor="email">Email</label>
                    <input type="email" id='email' name='email' />
                </div>
                <div className='formItem'>
                    <label htmlFor="password">Mot de passe</label>
                    <input type={pwdDisplayed ? "text" :"password"} id='password' name='password' />
                    {
                        pwdDisplayed ?
                            <svg onClick={(() => showPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                            </svg>
                        : 
                            <svg onClick={(() => showPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                            </svg>
                        
                    }
                </div>
                <button type='submit' className='validateFormBtn' id='validateFormBtn'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                    <span>Valider</span>
                </button>
                <p onClick={() => {
                    setForgottenPwdDisplayed(true);
                    setLoginDisplayed(false);
                }} className='forgottenPwdLink'>Mot de passe oublié ?</p>
            </form>
        </div>
    )
}
