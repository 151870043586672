import React, { useEffect, useState } from 'react';
import { helpers } from '../_services/helpers';
import slugify from 'react-slugify';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { axiosCaller } from '../_services/axiosCaller';
import jwt from 'jwt-decode';
import { userServices } from '../_services/user_services';

export default function Card( {titleCard, dateCard, colorBg, folderGallery, category, id, picturesNb, videosNb, cardPicture, likes}) {

    const username = useSelector((state) => state.username);
    const [likesMembers, setLikesMembers] = useState(likes);

    useEffect(() => {
        setLikesMembers(likes);
    }, [likes])

    const likeArticle = () => {
        /* stockNewActionInStore(true) */
        if (likesMembers.filter(member => member.email === userServices.getUser().email).length > 0) {
            setLikesMembers(likesMembers => likesMembers.filter((member) => member.email !== userServices.getUser().email));
        } else {
            setLikesMembers(likesMembers => [...likesMembers, {email: userServices.getUser().email, username: username}]);
        }
        axiosCaller.callAxios('/api/liked_article/' + id, 'POST', {
            "like": likesMembers.filter(member => member.email === userServices.getUser().email).length > 0 ? false : true
        })
        .then(response => {
            /* console.log(response) */

        })
        .catch(error => {
            /* setLiked(!liked); */
            /* console.log(error) */
        });
    }

    return (
        <li className='Card'>
            <NavLink to={'/'+ slugify(category) + '/' + id + '-' + slugify(titleCard)} className='CardLink'>
                <img src={helpers.baseUrl + "/medias/" + folderGallery + "/" + cardPicture} alt={titleCard + '-picture'} className='CardPicture'/>
                <div className='CardInfos'>
                    <div className='SvgCamera'>
                        {
                        picturesNb > 0 && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={colorBg} className="SvgCameraItem" viewBox="0 0 16 16">
                                <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
                                <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                            </svg>
                        )
                        }
                        {
                        videosNb > 0 && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={colorBg} className="SvgCameraItem" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                            </svg>
                        )
                        }
                    </div>
                    <h4>{titleCard}</h4>
                    <div className='MetaCard'>
                        <span className='dateBox' style={{backgroundColor: colorBg}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="calendar" viewBox="0 0 16 16">
                                <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                                <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                            </svg>
                            {helpers.formatedDate(dateCard)}
                        </span>
                    </div>
                </div>
            </NavLink>
            <div className='likeBox' onClick={() => likeArticle()}>
                {
                    likesMembers.filter(member => member.email === userServices.getUser().email).length > 0 ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#F70059" className="svgLike" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                        </svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#F70059" className="svgLike" viewBox="0 0 16 16">
                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                        </svg>
                }
                <span className='nbLikes'>{likesMembers && likesMembers.length > 0 ? likesMembers.length : ''}</span>
            </div>
        </li>
    )
}
