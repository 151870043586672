import React from 'react'

export default function HomeTitleBand() {
  return (
    <div className='HomeTitleBand'>
        <h1>La BAD</h1>
        <span className='Slogan'>"La Bande à Dainville, notre grande famille "</span>
        <p>
          Ce site est exclusivement réservé aux membres de la BAD.<br></br>
          Vous retrouverez une grande partie des photos et vidéos des moments partagés ensemble depuis 1996.
        </p>
    </div>
  )
}
