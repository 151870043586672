import React, { useEffect } from 'react';
import HomeCarousel from '../components/Home/HomeCarousel';
import HomeTitleBand from '../components/Home/HomeTitleBand';
import LastArticles from '../components/Home/LastArticles';
import NextEvent from '../components/Home/NextEvent';

export default function Home() {

  document.title = 'La BAD | Accueil';

  useEffect(() => {
    window.scroll(0,0);
  }, [])

   return (
    <div className='Home'>
        <HomeCarousel />
        <HomeTitleBand />
        <LastArticles />
        <NextEvent />
    </div>
  )
}
