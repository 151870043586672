import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function MyAccountSkeleton() {
    return (
        <SkeletonTheme baseColor="#F3F3F3" highlightColor="#ECEBEB">
            <div style={{
                display: 'flex',
                flexDirection: window.matchMedia("(min-width: 643px)").matches ? 'row' : 'column',
                alignItems: 'center',
                backgroundColor: '#FAFAFA',
                width: '100%',
                maxWidth: '1340px',
                borderRadius: '5px',
                padding: '20px'
            }}>
                <div style={{
                    marginRight: '50px'
                    }}>
                    <Skeleton width={200} height={200} borderRadius={100}/>
                </div>
                <div>
                    <div style={{
                        margin: '10px 0'
                        }}>
                        <Skeleton width={300} height={window.matchMedia("(min-width: 400px)").matches ? 24 : 48} />
                    </div>
                    <div style={{
                        margin: '10px 0'
                        }}>
                        <Skeleton width={300} height={window.matchMedia("(min-width: 400px)").matches ? 24 : 48} />
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: window.matchMedia("(min-width: 800px)").matches ? 'flex-start' : 'space-between',
                flexDirection: window.matchMedia("(min-width: 643px)").matches ? 'row' : 'column',
                alignItems: 'center',
                marginTop: '50px',
                width: '100%',
                maxWidth: '1340px',
            }}>
                <div style={{
                        marginRight: window.matchMedia("(min-width: 643px)").matches ? '30px' : '0',
                        marginBottom: window.matchMedia("(min-width: 643px)").matches ? '0' : '30px',
                    }}>
                    <Skeleton width={276} height={44} />
                </div>
                <div style={{

                    }}>
                    <Skeleton width={276} height={44} />
                </div>
            </div>
        </SkeletonTheme>
    )
}
