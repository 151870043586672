import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AccordionCompo from '../components/AccordionCompo';
import ArticleTitleBand from '../components/Article/ArticleTitleBand';
import DescriptionArticle from '../components/Article/DescriptionArticle';
import PicturesGallery from '../components/Article/PicturesGallery';
import VideosGallery from '../components/Article/VideosGallery';
import { axiosCaller } from '../_services/axiosCaller';
import { helpers } from '../_services/helpers';
import ArticleSkeleton from '../components/Article/ArticleSkeleton';

export default function Article() {
    
    let {slug} = useParams();
    let keyParam = slug.substring(0,slug.indexOf('-'));

    const [article, setArticle] = useState();

    // Gestion du loader, gif s'affichant pendant l'appel d'Axios avant le retour de sa réponse
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        window.scroll(0,0);
        axiosCaller.askCsrf()
        .then((response) => {
            axiosCaller.callAxios('/api/article/' + keyParam, 'GET', response.data)
            .then((response) => {
                setArticle(response.data);
                setLoader(false);
                document.title = 'La BAD | ' + response.data.title
            })
        })
    }, [])

    const getMembers = (members) => {
        let membersTab = [];
        members.forEach(member => {
            membersTab.push(member.username);
        });
        return membersTab;
    }

    return (
        <div className='Article'>
            {
                loader ? 
                    <ArticleSkeleton /> :
                    article &&
                        <>
                            <img src={helpers.baseUrl + "/medias/" + article.folder_gallery + "/" + article.header_picture} alt={article.title + '-picture'} className='HeaderPicture'/>
                            <ArticleTitleBand title={article.title} date={article.date} colorBg={article.category.color}/>
                            <DescriptionArticle
                                id={article.id}
                                date={article.date}
                                description={article.description}
                                members={
                                    article.members
                                        .sort((a,b) => {
                                            if (a.username > b.username) {
                                                return 1;
                                            } 
                                            if (a.username < b.username) {
                                                return -1;
                                            }
                                            return 0;
                                        })
                                        .map((member) => member.username)
                                        .join(', ')
                                }
                                likes={article.users_like}
                            />
                            <div className='AccordionMedias'>
                                {
                                    article.medias.filter((media) => media.type === 'picture').length > 0 && (
                                        <AccordionCompo
                                            accordionTitle={
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="camera" viewBox="0 0 16 16">
                                                        <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
                                                        <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                                    </svg>
                                                    Photos ({article.medias.filter((media) => media.type === 'picture').length})
                                                </span>
                                            }
                                            accordionContent={<PicturesGallery folderGallery={article.folder_gallery} medias={article.medias.filter((media) => media.type === 'picture')} color={article.category.color} />}
                                        />
                                    )
                                }
                                {
                                    article.medias.filter((media) => media.type === 'video').length > 0 && (
                                        <AccordionCompo 
                                            accordionTitle={
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="camera-video" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                                                    </svg>
                                                    Vidéos ({article.medias.filter((media) => media.type === 'video').length})
                                                </span>
                                            }
                                            accordionContent={<VideosGallery folderGallery={article.folder_gallery} medias={article.medias.filter((media) => media.type === 'video')} color={article.category.color} />}
                                        />
                                    )
                                }
                            </div>
                        </>
            }
         </div>
  )
}