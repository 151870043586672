import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateAlertMessage } from '../redux/redux';
import { axiosCaller } from '../_services/axiosCaller';
import CategoryTitleBand from '../components/CategoryTitleBand';
import Button from '../components/Button';
import { helpers } from '../_services/helpers';

export default function CreatePwd() {

    document.title = 'La BAD | Création mot de passe';

    let location = useLocation();

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const dispatchAlertMessage = useDispatch();
    const stockAlertMessageInStore = (data) => {
        dispatchAlertMessage(updateAlertMessage(data))
    }

    const uuid = useParams().uuid;

    // Validation du formulaire et envoi des valeurs vers l'API
    const validForm = (e) => {
        e.preventDefault();
        let formValues = {};
        for (let item of e.target) {
            if (item.name !== '') {
                formValues[item.name] = item.value;
            }
        }
        axiosCaller.callAxiosDisconnect('/api/user/create-password/' + uuid, 'POST',  {
            "password": formValues.password,
            "passwordConfirm": formValues.passwordConfirm
        })
        .then(response => {
            stockAlertMessageInStore({ type: 'success', content: 'Ton nouveau mot de passe a été créé avec succès.\nTu peux te connecter.' });
            navigate('/');
        })
        .catch(error => {
            stockAlertMessageInStore({ type: 'error', content: 'La création de ton mot de passe n\'a pu aboutir, merci de corriger les erreurs.' })
            setErrors(error.response.data);
        });
    }

    const [newPwdDisplayed, setNewPwdDisplayed] = useState();
    const [confirmPwdDisplayed, setConfirmPwdDisplayed] = useState();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const showNewPwd = () => {
        setNewPwdDisplayed(!newPwdDisplayed);
    }

    const showConfirmPwd = () => {
        setConfirmPwdDisplayed(!confirmPwdDisplayed);
    }

    return (
        <>
            <CategoryTitleBand slugCategory={location.pathname.slice(37, 60)}/>
            <div className='createPwd'>
                <NavLink to='/'>
                    <img src={helpers.baseUrl + '/LaBad-Logo.png'} alt='logo' className='LogoLogin'/>
                </NavLink>
                <div className='pwdMessage'>
                    <p className='rulesTitle'>Règles de création de votre mot de passe : </p>
                    <ul id='pwdRules'>
                        <li>Entre 8 et 20 caractères</li>
                        <li>Au moins une lettre minuscule</li>
                        <li>Au moins une lettre majuscule</li>
                        <li>Au moins 1 chiffre</li>
                        <li>Au moins 1 caractère spécial (- + ! * $ @ % _)</li>
                    </ul>
                </div>
                <form onSubmit={(e) => validForm(e)}>
                    <div className="infosGroup">
                        <div className='formItem newPwdItem'>
                            <label htmlFor="password">Nouveau mot de passe</label>
                            <input type={newPwdDisplayed ? "text" :"password"} id='password' name='password' style={{ border: errors && errors.password !== undefined ? '1px solid #ECACAC' : '' }} />
                            {
                                newPwdDisplayed ?
                                    <svg onClick={(() => showNewPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                    </svg>
                                : 
                                    <svg onClick={(() => showNewPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                                    </svg>
                                
                            }
                            {
                                errors && errors.password !== undefined ?
                                    <p className='errorItem'>{errors.password}</p> :
                                    ''
                            }
                        </div>
                        <div className='formItem confirmPwdItem'>
                            <label htmlFor="passwordConfirm">Confirmation du mot de passe</label>
                            <input type={confirmPwdDisplayed ? "text" :"password"} id='passwordConfirm' name='passwordConfirm' style={{ border: errors && errors.passwordConfirm !== undefined ? '1px solid #ECACAC' : '' }} />
                            {
                                confirmPwdDisplayed ?
                                    <svg onClick={(() => showConfirmPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                    </svg>
                                : 
                                    <svg onClick={(() => showConfirmPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                                    </svg>
                                
                            }
                            {
                                errors && errors.passwordConfirm !== undefined ?
                                    <p className='errorItem'>{errors.passwordConfirm}</p> :
                                    ''
                            }
                        </div>
                    </div>
                    <div className="actionBtns">
                        <Button
                            typeBtn='back'
                            btnSvg='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                </svg>'
                            btnTitle='Annuler'
                            btnUrl='/'
                        />
                        <button type='submit' id='validateFormBtn' className='validateFormBtn'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>
                            <span>Valider</span>
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}