import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import NavBar from './components/NavBar';
import Article from './pages/Article';
import Footer from './components/Footer';
import CategoryPage from './pages/CategoryPage';
import CreatePwd from './pages/CreatePwd';
import LoginPage from './pages/LoginPage';
import AlertMessage from './components/AlertMessage';
import PrivateRoutes from './PrivatesRoutes/PrivateRoutes';
import MyAccount from './pages/MyAccount/MyAccount';
import EditAccount from './pages/MyAccount/EditAccount';
import ModifyPassword from './pages/MyAccount/ModifyPassword';
import E404 from './pages/E404';

function App() {
    return (
        <div className="App">
            <NavBar />
            <AlertMessage />
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/:uuid/creer-mon-mot-de-passe" element={<CreatePwd />} />
                <Route element={<PrivateRoutes />}>
                    <Route path='/accueil' element={<Home />}/>
                    <Route path='/:slugCategory' element={<CategoryPage />}/>
                    <Route path='/:slugCategory/:slug' element={<Article />}/>
                    <Route path='/mon-compte' element={<MyAccount />} />
                    <Route path='/mon-compte/modifier-mes-informations' element={<EditAccount />} />
                    <Route path='/mon-compte/modifier-mon-mot-de-passe' element={<ModifyPassword />} />
                </Route>
                <Route path='*' element={<E404 />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;