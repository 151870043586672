import jwt from 'jwt-decode';

const saveToken = (token) => {
    localStorage.setItem('token', token);
}

const isConnected = () => {
    return !!localStorage.getItem('token');
}

const getToken = () => {
    return localStorage.getItem('token');
}

const getUser = () => {
    return jwt(localStorage.getItem('token'));
}

const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('profilePicture');
}

const saveUsername = (username) => {
    localStorage.setItem('username', username)
}

const getUsername = () => {
    return localStorage.getItem('username')

}

const saveProfilePicture = async (profilePicture) => {
    localStorage.setItem('profilePicture', profilePicture)
}

const getProfilePicture = () => {
    return localStorage.getItem('profilePicture')
}

export const userServices = {
    saveToken, isConnected, getToken, getUser, logout, saveUsername, getUsername, saveProfilePicture, getProfilePicture
};