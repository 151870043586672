import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CategoryTitleBand from '../components/CategoryTitleBand';
import Articles from '../components/Articles';
import { axiosCaller } from '../_services/axiosCaller';

export default function CategoryPage() {

    let {slugCategory} = useParams();

    const [category, setCategory] = useState([]);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        window.scroll(0,0);
        if (slugCategory === 'tout-au-fil-du-temps') {
            document.title = 'La BAD | Tout au fil du temps'
                axiosCaller.callAxios('/api/articles', 'GET')
                .then((response) => {
                    setArticles(response.data);
                })
                
        } else {
            axiosCaller.callAxios('/api/category/' + slugCategory, 'GET')
            .then((response) => {
                setCategory(response.data);
                document.title = 'La BAD | ' + response.data.title
            })
        }

        return () => {
            setCategory([]);
            setArticles([]);
        }

        }, [slugCategory]);

    return (      
        <div className='CategoryPage'>
            <CategoryTitleBand slugCategory={slugCategory}/>
            {
                /* slugCategory === 'tout-au-fil-du-temps' ?
                <>
                    <Articles key={slugCategory} slugCategory={slugCategory} articles = {articles} categoryTitle='Tout au fil du temps' categoryColor='#0c71c3'/>
                </>
                : */
                <>
                    <Articles key={slugCategory} articles = {category.articles} categoryTitle={category.title} categoryColor={category.color}/>
                </>
            }
        </div>
    )
}
