import React, { useState, useEffect } from 'react';
import Button from '../../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateAlertMessage } from '../../redux/redux';
import { axiosCaller } from '../../_services/axiosCaller';
import { checkToken } from '../../_services/checkToken';
import { userServices } from '../../_services/user_services';
import CategoryTitleBand from '../../components/CategoryTitleBand';
import { helpers } from '../../_services/helpers';
import EditAccountSkeleton from '../../components/EditAccountSkeleton';

export default function EditAccount() {

    document.title = 'La BAD | Modification informations personnelles';
    
    let location = useLocation();
    
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    
    const dispatchAlertMessage = useDispatch();
    const stockAlertMessageInStore = (data) => {
        dispatchAlertMessage(updateAlertMessage(data))
    }
    
    const [user, setUser] = useState();

    // Gestion du loader, gif s'affichant pendant l'appel d'Axios avant le retour de sa réponse
    const [loader, setLoader] = useState(true);
    
    useEffect(() => {
        window.scroll(0,0);
        axiosCaller.callAxios('/api/user', 'GET')
        .then((response) => {
            setUser(response.data);
            setLoader(false);
        })
    }, [])

    const handleChange = (e) => {
        setUser({
            ...user, 
            [e.target.name]: e.target.value
        });
    }
    // Validation du formulaire et envoi des valeurs vers l'API
    const validForm = (e) => {
        e.preventDefault();
        if (checkToken.expired()) {
            stockAlertMessageInStore({type: 'error', content: 'Ta session a expirée, merci de te reconnecter.'})
            userServices.logout();
            navigate('/');
        } else {
            /* let formValues = {};
            for (let item of e.target) {
                if (item.name !== '') {
                    formValues[item.name] = item.value;
                }
            } */
            const formData = new FormData();
            for (let item of e.target) {
                if (item.name === 'profilePicture' && profilePictureFile) {
                    formData.append('profilePictureFile', profilePictureFile);
                } else if (item.id !== '') {
                    formData.append(item.id, item.value);
                }
            }
            /* axiosCaller.askCsrf()
            .then((response) => { */
                axiosCaller.callAxiosFile('/api/user/edit', 'POST'/* , response.data */, formData)
                .then(response => {
                    userServices.saveUsername(response.data.username);
                    userServices.saveProfilePicture(response.data.picture);
                    stockAlertMessageInStore({type: 'success', content: 'Tes informations personnelles ont été modifiées avec succès.'})
                    navigate('/mon-compte');
                })
                .catch(error => {
                    console.log(error)
                    stockAlertMessageInStore({type: 'error', content: 'La modification de tes informations personnelles n\'a pu aboutir, merci de corriger les erreurs.'})
                    setErrors(error.response.data);
                });
            /* }) */
        }
    }

    const [profilePictureFile, setProfilePictureFile] = useState();
    const handleProfilePictureFile = (e) => {
        setProfilePictureFile(e.target.files[0])
    }

    console.log(profilePictureFile)

    return (
        <>
            <CategoryTitleBand slugCategory={location.pathname}/>
            <div className='editAccount'>
            {
                loader ? 
                    <EditAccountSkeleton /> :
                <form noValidate onSubmit={(e) => validForm(e)}>
                    <div className='userInfosAndPicture'>
                        <div className="pictureBox">
                            <input type="file" className='profilePictureInput' id='profilePicture' name='profilePicture' onChange={(e) => handleProfilePictureFile(e)} accept="image/jpeg, image/png"/>
                            <label className='profilePictureLabel' htmlFor="profilePicture" style={{border: errors && errors.logo !== undefined ? '1px solid #ECACAC' : ''}}>
                                {
                                    profilePictureFile ?
                                    <img src={URL.createObjectURL(profilePictureFile)} alt="profilePicture" className='profilePicture' />
                                    :
                                    <img src={helpers.baseUrl + "/medias/profilePictures/" + user.picture} alt="profilePicture" className='profilePicture' />
                                }
                                <p className='textModifyPicture'>Modifier</p>
                            </label>
                            {
                                errors && errors.profilePicture !== undefined ?
                                <p className='errorItem'>{errors.profilePicture}</p> :
                                ''
                            }
                        </div>
                        <div className='userInfos'>
                            <div className="infosGroup">
                                <div className='formItem'>
                                    <label htmlFor="username">Pseudo</label>
                                    <input type="text" id='username' name='username' value={user.username} onChange={handleChange} style={{border: errors && errors.username !== undefined ? '1px solid #ECACAC' : ''}}/>
                                    {
                                        errors && errors.username !== undefined ?
                                        <p className='errorItem'>{errors.username}</p> :
                                        ''
                                    }
                                </div>
                            </div>
                            <div className="infosGroup">
                                <div className='formItem'>
                                    <label htmlFor="email">Email</label>
                                    <input className='fieldMail' type="email" id='email' name='email' value={user.email} onChange={handleChange} style={{border: errors && errors.email !== undefined ? '1px solid #ECACAC' : ''}} disabled/>
                                    <p className='emailInfo'>Pour modifier ton email, contacte directement Jean'Ouis</p>
                                    {/* {
                                        errors && errors.email !== undefined ?
                                        <p className='errorItem'>{errors.email}</p> :
                                        ''
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actionBtns">
                        <Button 
                            typeBtn='back'
                            btnSvg='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                </svg>'
                            btnTitle='Annuler'
                            btnUrl='/mon-compte'
                        />
                        <button type='submit' className='validateFormBtn'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            <span>Valider</span>
                        </button>
                    </div>
                </form>
            }               
            </div>
        </>
    )
}
