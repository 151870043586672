import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import CategoryTitleBand from '../../components/CategoryTitleBand';
import MyAccountSkeleton from '../../components/MyAccountSkeleton';
import { axiosCaller } from '../../_services/axiosCaller';
import { helpers } from '../../_services/helpers';

export default function MyAccount() {

    document.title = 'La BAD | Mon Compte';

    let location = useLocation();
    
    const alertMessage = useSelector((state) => state.alertMessage);
    const [user, setUser] = useState();
    
    // Gestion du loader, gif s'affichant pendant l'appel d'Axios avant le retour de sa réponse
    const [loader, setLoader] = useState(true);
    
    useEffect(() => {
        window.scroll(0,0);
        axiosCaller.callAxios('/api/user', 'GET')
        .then((response) => {
            setUser(response.data);
            setLoader(false);
        })
    }, [])

    return (
        <>
            <CategoryTitleBand slugCategory={location.pathname}/>
            <div className='myAccount'>
                {
                    loader ? 
                        <MyAccountSkeleton /> :
                        <div className='userInfosAndBtns'>
                            <div className='userInfosAndPicture'>
                                <img src={helpers.baseUrl + "/medias/profilePictures/" + user.picture} alt="profilePicture" className='profilePicture' />
                                <div className='userInfos'>
                                    <div className='usernameItem infoItem'>
                                        <span>Pseudo : </span>
                                        <span className='username valueItem'>{user.username}</span>
                                    </div>
                                    <div className='emailItem infoItem'>
                                        <span>Email : </span>
                                        <span className='email valueItem'>{user.email}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="actionBtns">
                                <Button
                                    typeBtn='modify'
                                    btnSvg='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                        </svg>'
                                    btnTitle='Modifier mes informations'
                                    btnUrl='modifier-mes-informations'
                                />
                                <Button
                                    typeBtn='modifyPassword'
                                    btnSvg='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                                        <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"/>
                                        <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                        </svg>'
                                    btnTitle='Modifier mon mot de passe'
                                    btnUrl='modifier-mon-mot-de-passe'
                                />
                            </div>
                        </div>
                }
            </div>     
        </>
    )
}