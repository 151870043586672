import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

export default function Button({ typeBtn, btnSvg, btnTitle, btnUrl }) {

    const navigate = useNavigate();  

    return (
        <div className='button' id={typeBtn}>
            <NavLink to={btnUrl}>
                <div className='contentSvg' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(btnSvg)}}/>
                <span>{btnTitle}</span>
            </NavLink>
        </div>
    )
}