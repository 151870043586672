import React, { useEffect, useState } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-video.css';
import lgVideo from "lightgallery/plugins/video";
import { helpers } from '../../_services/helpers';
import Pagination from '../Pagination';
import { paginationParams } from '../../_services/paginationParams';

export default function VideosGallery({folderGallery, medias, color}) {

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const lastItemIndex = currentPage * paginationParams.mediasPerPage;
    const firstItemIndex = lastItemIndex - paginationParams.mediasPerPage;

    useEffect(() => {
        setCurrentPage(currentPage);
    }, [])

    const options = {
        buttons: {
            showDownloadButton: false
        },
        caption: {
            showCaption: false
        },
        progressBar: {
            backgroundColor: '#0c71c3'
        }
    }

    return (
        <div className='VideosGalleryList'>
            <LightGallery
                speed={500}
                plugins={[lgThumbnail, lgVideo]}
                download={false}
                >
                {
                    medias.map((media) => {
                        return (
                            <>
                                <a
                                    data-video={'{"source": [{"src":"' + helpers.baseUrl + '/medias/' + folderGallery + '/videos/' + media.name + '", "type":"video/mp4"}], "attributes": {"preload": false, "playsinline": true, "controls": true, "controlsList":"nodownload", "disablePictureInPicture":false}}'}
                                    data-poster={helpers.baseUrl + '/medias/' + folderGallery + '/videos/thumbs/' + media.thumb}
                                    key={media}
                                    >
                                    <div className='videoBox'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="svgPlay" viewBox="0 0 512 512" fill="currentColor">
                                            <path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/>
                                            <path d="M216.32 334.44l114.45-69.14a10.89 10.89 0 000-18.6l-114.45-69.14a10.78 10.78 0 00-16.32 9.31v138.26a10.78 10.78 0 0016.32 9.31z"/>
                                        </svg>
                                        <img                                       
                                            class="img-responsive"
                                            src={helpers.baseUrl + '/medias/' + folderGallery + '/videos/thumbs/' + media.thumb}
                                        />
                                    </div>
                                </a>
                            </>
                        )
                    })  
                    /* .slice(firstItemIndex, lastItemIndex) */
                }
            </LightGallery>
            {/* {
                medias.length > paginationParams.mediasPerPage ?
                <Pagination
                    totalItems={
                        medias.length
                        }
                    itemsPerPage={paginationParams.mediasPerPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    color={color}
                    /> : null
            } */}
        </div>
    )
}