import React, { useEffect, useState } from 'react';
import { axiosCaller } from '../../_services/axiosCaller';
import { helpers } from '../../_services/helpers';

export default function NextEvent() {

    const [nextEvent, setNextEvent] = useState();

    useEffect(() => {
        axiosCaller.askCsrf()
        .then((response) => {
            axiosCaller.callAxios('/api/next_event', 'GET', response.data)
            .then((response) => {
                setNextEvent(response.data[0]);
            })
        })
    }, [])

    return (
        <>
            {
                nextEvent && nextEvent.title !== 'no_event' &&
                    <div className='NextEventBox'>
                        <h2 style={{background:'linear-gradient(45deg, rgb(46, 50, 53), ' + nextEvent.category.color + ')'}}>Le Prochain Evénement</h2>
                        <div className='NextEvent' style={{background: nextEvent.category.color +'50', borderTop: 'solid 3px ' + nextEvent.category.color, borderBottom: 'solid 3px ' + nextEvent.category.color}}>
                            <img src={helpers.baseUrl + '/next_event/' + nextEvent.id + '/' + nextEvent.picture1} alt={nextEvent.title + '1-picture'} className='NextEventPicture'/>
                            <div className='DescrAndMap'>
                                <div className='DescriptionEvent'>
                                    <h3>{nextEvent.title}</h3>
                                    <h5>{nextEvent.subtitle}</h5>
                                    <p>
                                        {nextEvent.description}
                                    </p>
                                    <img src={helpers.baseUrl + '/next_event/' + nextEvent.id + '/' + nextEvent.picture2} alt={nextEvent.title + '2-picture'} className='NextEventPictureDescr'/>
                                </div>
                                {
                                    nextEvent.map &&
                                    <iframe src={nextEvent.map} className='MapEvent' loading='lazy'/>
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
