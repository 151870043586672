import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function ArticlesSkeleton() {

    if (window.matchMedia("(min-width: 1000px)").matches) {}
    return (
        <SkeletonTheme baseColor="#F3F3F3" highlightColor="#ECEBEB">
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}>
                <div style={{
                    width: '90%',
                    maxWidth: '960px',
                    marginBottom: '50px',
                }}>
                    <Skeleton height={53} />
                </div>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                }}>
                    {
                        [1,2,3,4,5,6,7,8,9,10,11,12].map(() => (
                            <div style={{
                                width: 
                                    window.matchMedia("(min-width: 1000px)").matches ? '23%' :
                                    window.matchMedia("(min-width: 856px)").matches ? '31%' :
                                    window.matchMedia("(min-width: 726px)").matches ? '47%' :
                                    '97%',
                                margin: '10px',
                            }}>
                                <Skeleton height={200}/>
                            </div>
                        ))
                    }
                </div>
                <div style={{
                    width: '90%',
                    maxWidth: '960px',
                    marginTop: '50px',
                }}>
                    <Skeleton height={53} />
                </div>
            </div>
        </SkeletonTheme>
    )
}