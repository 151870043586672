import React from 'react';
import { helpers } from '../../_services/helpers'

export default function ArticleTitleBand( {title, date, colorBg} ) {
 
  return (
    <div
      className='ArticleTitleBand'
      style={
        {
          background:'linear-gradient(45deg, rgb(46, 50, 53), ' + colorBg + ')'
        }
      }
    >
        <div className='BoxInfosBand'>
          <h2>{title}</h2>
        </div>
    </div>
  )
}