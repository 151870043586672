import { configureStore, createSlice } from '@reduxjs/toolkit'

// Slice to stock message for success and error
const alertMessageSlice = createSlice({
    name: 'alertMessage',
    initialState: '',
    reducers: {
        updateAlertMessage: (state, action) => {
            state = action.payload
            return state
        }
    }
})
export const {updateAlertMessage} = alertMessageSlice.actions;

// Slice to stock infos for modal
const modalSlice = createSlice({
    name: 'modal',
    initialState: '',
    reducers: {
        updateModal: (state, action) => {
            state = action.payload
            return state
        }
    }
})
export const {updateModal} = modalSlice.actions;

// Slice to stock filter's choices
const filterSlice = createSlice({
    name: 'filter',
    initialState: {/* type: '', */ search: '', state: 'all'},
    reducers: {
        updateFilter: (state, action) => {
            state = action.payload
            return state
        }
    }
})
export const {updateFilter} = filterSlice.actions;

// Slice to stock the response of axios
const axiosAnswerSlice = createSlice({
    name: 'axiosAnswer',
    initialState: '',
    reducers: {
        updateAxiosAnswer: (state, action) => {
            state = action.payload
            return state
        }
    }
})
export const {updateAxiosAnswer} = axiosAnswerSlice.actions;

// Slice to stock the loader
const loaderSlice = createSlice({
    name: 'loader',
    initialState: false,
    reducers: {
        updateLoader: (state, action) => {
            state = action.payload
            return state
        }
    }
})
export const {updateLoader} = loaderSlice.actions;

export const store = configureStore({
    reducer: {
        alertMessage: alertMessageSlice.reducer,
        modal: modalSlice.reducer,
        filter: filterSlice.reducer,
        axiosAnswer: axiosAnswerSlice.reducer,
        loader: loaderSlice.reducer
    }
})