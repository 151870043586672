import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AccordionCompo({accordionTitle, accordionContent}) {
    return (
        <Accordion className='Accordion' TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
                <Typography component={'div'} className='SectionTitle'>
                    {accordionTitle}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component={'div'}>
                    {accordionContent}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}
