import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function EditAccountSkeleton() {
    return (
        <SkeletonTheme baseColor="#F3F3F3" highlightColor="#ECEBEB">
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#FAFAFA',
                width: '100%',
                maxWidth: '800px',
                borderRadius: '5px',
                padding: '20px'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: window.matchMedia("(min-width: 800px)").matches ? 'row' : 'column', 
                    alignItems: 'center',
                    width: '100%',
                }}>
                    <div style={{
                        marginRight: window.matchMedia("(min-width: 800px)").matches ? '50px' : '0',
                        marginBottom: window.matchMedia("(min-width: 800px)").matches ? '0' : '50px',
                        }}>
                        <Skeleton width={200} height={200} borderRadius={100}/>
                    </div>
                    <div style={{
                        width: '100%'
                    }}>
                        <div style={{
                            margin: '10px 0',
                            width: '100%',
                            }}>
                            <Skeleton height={64} />
                        </div>
                        <div style={{
                            margin: '10px 0',
                            width: '100%',
                            }}>
                            <Skeleton height={64} />
                        </div>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '50px',
                    width: '100%',
                    maxWidth: '1340px',
                }}>
                    <div style={{

                        }}>
                        <Skeleton width={window.matchMedia("(min-width: 400px)").matches ? 110 : 40} height={window.matchMedia("(min-width: 400px)").matches ? 44 : 40} />
                    </div>
                    <div style={{

                        }}>
                        <Skeleton width={window.matchMedia("(min-width: 400px)").matches ? 110 : 40} height={window.matchMedia("(min-width: 400px)").matches ? 44 : 40} />
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}
