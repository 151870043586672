import axios from "axios";
import { userServices } from './user_services';
import { helpers } from './helpers';

const callAxiosDisconnect = (path, method, formData) => {
    return axios(helpers.baseUrl + path, {
        method: method,
        headers: {
            'content-type': 'application/json'
        },
        data: formData
    })
}

const askCsrf = () => {
    return axios.get(helpers.baseUrl + '/api/csrf', {
        headers: {
            Authorization: 'Bearer ' + userServices.getToken()
        }
    })
}

const callAxios = (path, method, /* csrfToken, */ formData) => {
    return axios(helpers.baseUrl + path, {
        method: method,
        headers: {
            Authorization: 'Bearer ' + userServices.getToken(),
            /* 'X-CSRF-TOKEN': csrfToken, */
            'content-type': 'application/json'
        },
        data: formData
    })   
}

const callAxiosFile = (path, method, /* csrfToken, */ formData) => {
    return axios(helpers.baseUrl + path, {
        method: method,
        headers: {
            Authorization: 'Bearer ' + userServices.getToken(),
            /* 'X-CSRF-TOKEN': csrfToken, */
            'content-type': 'multipart/form-data'
        },
        data: formData
    })   
}



export const axiosCaller = {
    callAxiosDisconnect, askCsrf, callAxios, callAxiosFile
};