import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function LastArticlesSkeleton() {

    return (
        <SkeletonTheme baseColor="#C8DEF0" highlightColor="#BBCFE0">
            <div style={{
                display: 'flex',
                flexDirection: window.matchMedia("(min-width: 2000px)").matches ? 'row' : 'column',
                alignItems: 'center',
                width: '90%'
            }}>
                <div style={{
                    width: '100%',
                    maxWidth: '1220px',
                    marginRight: window.matchMedia("(min-width: 2000px)").matches ? '10px' : '',
                    marginBottom: window.matchMedia("(min-width: 727px)").matches ? '0' : '5px'
                }}>
                    <Skeleton height={window.matchMedia("(min-width: 727px)").matches ? 458 : 222} />
                </div>
                <div style={{
                    width: '100%',
                    maxWidth: '1220px',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: window.matchMedia("(min-width: 727px)").matches ? 'row' : 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <div style={{
                            width: window.matchMedia("(min-width: 727px)").matches ? '50%' : '100%',
                            margin: window.matchMedia("(min-width: 2000px)").matches ? '5px 10px' : window.matchMedia("(min-width: 727px)").matches ? '10px 10px 5px 0' : '5px 0',
                        }}>
                            <Skeleton height={222} />
                        </div>
                        <div style={{
                            width: window.matchMedia("(min-width: 727px)").matches ? '50%' : '100%',
                            margin: window.matchMedia("(min-width: 2000px)").matches ? '5px 10px' : window.matchMedia("(min-width: 727px)").matches ? '10px 0 5px 10px' : '5px 0',
                        }}>
                            <Skeleton height={222} />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: window.matchMedia("(min-width: 727px)").matches ? 'row' : 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <div style={{
                            width: window.matchMedia("(min-width: 727px)").matches ? '50%' : '100%',
                            margin: window.matchMedia("(min-width: 2000px)").matches ? '5px 10px' : window.matchMedia("(min-width: 727px)").matches ? '5px 10px 5px 0' :'5px 0',
                        }}>
                            <Skeleton height={222} />
                        </div>
                        <div style={{
                            width: window.matchMedia("(min-width: 727px)").matches ? '50%' : '100%',
                            margin: window.matchMedia("(min-width: 2000px)").matches ? '5px 10px' : window.matchMedia("(min-width: 727px)").matches ? '5px 0 5px 10px' :'5px 0',
                        }}>
                            <Skeleton height={222} />
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}