import React, { useState, useEffect} from 'react';
import Card from '../Card';
import { axiosCaller } from '../../_services/axiosCaller';
import LastArticlesSkeleton from './LastArticlesSkeleton';

export default function LastArticles() {

    const [articles, setArticles] = useState([]);
    let colorCard = '#0c71c3';
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        axiosCaller.callAxios('/api/last-articles', 'GET')
        .then((response) => {
            setArticles(response.data);
            setLoader(false);
        })
        .catch((error)=>console.log(error))

        return () => {
            setLoader(true);
        }
    }, []);


    return (
        <div className='LastArticlesBox'>
            {
                <>
                    <h2 style={{background:'linear-gradient(45deg, rgb(46, 50, 53), #0c71c3)'}}>Les Derniers Articles</h2>
                    <div className='LastArticles'>
                    {
                        loader ? 
                            <LastArticlesSkeleton /> :
                            <>
                                <div className='LastArticlesLeft'>
                                    {
                                        articles.slice(0, 1).map((article) => {
                                            return (
                                                <Card
                                                    key={article.id}
                                                    titleCard={article.title}
                                                    dateCard={article.date}
                                                    colorBg={colorCard}
                                                    folderGallery={article.folder_gallery}
                                                    category={article.category.title}
                                                    id={article.id}
                                                    picturesNb={article.pictures_nb}
                                                    videosNb={article.videos_nb}
                                                    cardPicture={article.card_picture}
                                                    likes={article.users_like}
                                                />
                                            )
                                        })
                                    } 
                                </div>
                                <div className='LastArticlesRight'>
                                    <ul className='LastArticlesRight1stLine'>
                                        {
                                            articles.slice(1, 3).map((article) => {
                                                return (
                                                    <Card
                                                        key={article.id}
                                                        titleCard={article.title}
                                                        dateCard={article.date}
                                                        colorBg={colorCard}
                                                        folderGallery={article.folder_gallery}
                                                        category={article.category.title}
                                                        id={article.id}
                                                        picturesNb={article.pictures_nb}
                                                        videosNb={article.videos_nb}
                                                        cardPicture={article.card_picture}
                                                        likes={article.users_like}
                                                    />
                                                )
                                            })
                                        }   
                                    </ul>
                                    <ul className='LastArticlesRight2ndLine'>
                                        {
                                            articles.slice(3, 5).map((article) => {
                                                return (
                                                    <Card
                                                        key={article.id}
                                                        titleCard={article.title}
                                                        dateCard={article.date}
                                                        colorBg={colorCard}
                                                        folderGallery={article.folder_gallery}
                                                        category={article.category.title}
                                                        id={article.id}
                                                        picturesNb={article.pictures_nb}
                                                        videosNb={article.videos_nb}
                                                        cardPicture={article.card_picture}
                                                        likes={article.users_like}
                                                    />
                                                )
                                            })
                                        }   
                                    </ul>
                                </div>
                            </>
                    }
                    </div>
                </>
            }
        </div>
    )
}
