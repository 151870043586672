import React, { useState, useEffect, useRef } from 'react';
import Card from './Card';
import AccordionCompo from '../components/AccordionCompo';
import { axiosCaller } from '../_services/axiosCaller';
import { helpers } from '../_services/helpers';
import { paginationParams } from '../_services/paginationParams';
import Pagination from './Pagination';
import ArticlesSkeleton from './ArticlesSkeleton';

export default function Articles( {categoryTitle, categoryColor, articles} ) {

    const [sortedArticles, setSortedArticles] = useState();
    const [subcategories, setSubcategories] = useState([]);

    const [loader, setLoader] = useState(true);
    
    const [search, setSearch] = useState('');
    const [year, setYear] = useState('headerSelect');
    const [theme, setTheme] = useState('headerSelect');

    const searchRef = useRef({});
    const yearSelectRef = useRef({});
    const themeSelectRef = useRef({});

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const lastItemIndex = currentPage * paginationParams.articlesPerPage;
    const firstItemIndex = lastItemIndex - paginationParams.articlesPerPage;

    useEffect(() => {
        searchRef.current.value = '';
        yearSelectRef.current.value = 'headerSelect';
        themeSelectRef.current.value = 'headerSelect';
        setSearch('');
        setYear('headerSelect');
        setTheme('headerSelect');
        setSortedArticles([]);
        if (articles) {
            const articlesObj = Object.keys(articles).map((i) => articles[i]);
            const sortedArray = articlesObj.sort((a,b) => {
                return new Date(b.date) - new Date(a.date);
            });
            setSortedArticles(sortedArray);
            setLoader(false);
        }
        axiosCaller.callAxios('/api/subcategories', 'GET')
        .then((response) => {
            setSubcategories(response.data);
        })
        setCurrentPage(currentPage);

        return () => {
            setLoader(true);
        }

    }, [categoryTitle]);

    const handleChange = (e) => {
        switch (e.target.id) {
            case 'search':
                setSearch(e.target.value);
                break;
            case 'year':
                setYear(e.target.value);
                break;
            case 'theme':
                setTheme(e.target.value);
                break;
        }
    }

    return (
        <div className='Articles'>
            {
                loader ?
                    <ArticlesSkeleton /> :
                    articles &&
                    <>
                            <div className='accordion'>
                                <AccordionCompo
                                    accordionTitle={
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="Filter" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M10.5 1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4H1.5a.5.5 0 0 1 0-1H10V1.5a.5.5 0 0 1 .5-.5ZM12 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Zm-6.5 2A.5.5 0 0 1 6 6v1.5h8.5a.5.5 0 0 1 0 1H6V10a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5ZM1 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 1 8Zm9.5 2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V13H1.5a.5.5 0 0 1 0-1H10v-1.5a.5.5 0 0 1 .5-.5Zm1.5 2.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Z"/>
                                            </svg>
                                            FILTRES
                                        </span>
                                    }
                                    accordionContent={
                                        <div className='filters'>
                                            <div className='formItem' id='searchBox'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={categoryColor} className="searchSvg" viewBox="0 0 16 16">
                                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                                </svg>
                                                <input 
                                                    ref={searchRef}
                                                    style={{border: '1px solid ' + categoryColor}}
                                                    type="text" id='search'
                                                    name='search' placeholder='Rechercher'
                                                    value={search} onChange={(e) => handleChange(e)}
                                                >
                                                </input>
                                            </div>
                                            <div className='formItem' id='yearFilterBox'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={categoryColor} className="calendar" viewBox="0 0 16 16">
                                                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                </svg>
                                                <select
                                                    ref={yearSelectRef}
                                                    style={{border: '1px solid ' + categoryColor}}
                                                    id='year' name='year'
                                                    selected={year} onChange={(e) => handleChange(e)}
                                                >
                                                    <option value='headerSelect' defaultValue>
                                                        Filtre par année
                                                    </option>
                                                    {
                                                        subcategories
                                                            .filter((category) => category.type === "year")
                                                            .map((subcategory) => {
                                                                return (
                                                                    <option key={subcategory.title} value={subcategory.title}>{subcategory.title}</option>
                                                                )
                                                            })
                                                    }
                                                </select>
                                            </div>
                                            <div className='formItem' id='themeFilterBox'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={categoryColor} className="bi bi-balloon" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 9.984C10.403 9.506 12 7.48 12 5a4 4 0 0 0-8 0c0 2.48 1.597 4.506 4 4.984ZM13 5c0 2.837-1.789 5.227-4.52 5.901l.244.487a.25.25 0 1 1-.448.224l-.008-.017c.008.11.02.202.037.29.054.27.161.488.419 1.003.288.578.235 1.15.076 1.629-.157.469-.422.867-.588 1.115l-.004.007a.25.25 0 1 1-.416-.278c.168-.252.4-.6.533-1.003.133-.396.163-.824-.049-1.246l-.013-.028c-.24-.48-.38-.758-.448-1.102a3.177 3.177 0 0 1-.052-.45l-.04.08a.25.25 0 1 1-.447-.224l.244-.487C4.789 10.227 3 7.837 3 5a5 5 0 0 1 10 0Zm-6.938-.495a2.003 2.003 0 0 1 1.443-1.443C7.773 2.994 8 2.776 8 2.5c0-.276-.226-.504-.498-.459a3.003 3.003 0 0 0-2.46 2.461c-.046.272.182.498.458.498s.494-.227.562-.495Z"/>
                                                </svg>
                                                <select
                                                    ref={themeSelectRef}
                                                    style={{border: '1px solid ' + categoryColor}}
                                                    id='theme' name='theme'
                                                    selected={theme} onChange={(e) => handleChange(e)}
                                                >
                                                    <option value='headerSelect' defaultValue>Filtre par thème</option>
                                                    {
                                                        subcategories
                                                            .filter((category) => category.type === "theme")
                                                            .map((subcategory) => {
                                                                return (
                                                                    <option key={subcategory.title} value={subcategory.title}>{subcategory.title}</option>
                                                                )
                                                            })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    />
                            </div>
                            {
                                sortedArticles
                                .filter((article) => (
                                    (helpers.replaceAccent(article.title).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase()) ||
                                    helpers.replaceAccent(article.description).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase()) ||
                                    article.members.some(member => helpers.replaceAccent(member.username).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase())))
                                    &&
                                    (year !== 'headerSelect' ? article.subcategories.some(subcategory => year === subcategory.title) : 'headerSelect')
                                    &&
                                    (theme !== 'headerSelect' ? article.subcategories.some(subcategory => theme === subcategory.title) : 'headerSelect')
                                )).length === 0 ?
                                <p className='messageNoArticle'>Aucun article ne correspond à votre recherche.</p> :
                                <ul className='ArticlesList'>
                                    {
                                        sortedArticles
                                            .filter((article) => (
                                                (helpers.replaceAccent(article.title).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase()) ||
                                                helpers.replaceAccent(article.description).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase()) ||
                                                article.members.some(member => helpers.replaceAccent(member.username).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase())))
                                                &&
                                                (year !== 'headerSelect' ? article.subcategories.some(subcategory => year === subcategory.title) : 'headerSelect')
                                                &&
                                                (theme !== 'headerSelect' ? article.subcategories.some(subcategory => theme === subcategory.title) : 'headerSelect')
                                            ))
                                            .map((article) => {
                                                return (
                                                    <Card 
                                                        key={article.id}
                                                        titleCard={article.title}
                                                        dateCard={article.date}
                                                        colorBg={categoryColor}
                                                        folderGallery={article.folder_gallery}
                                                        category={categoryTitle}
                                                        id={article.id}
                                                        picturesNb={article.pictures_nb}
                                                        videosNb={article.videos_nb}
                                                        cardPicture={article.card_picture}
                                                        likes={article.users_like}
                                                    />
                                                )
                                            })
                                            .slice(firstItemIndex, lastItemIndex)
                                    }   
                                </ul>
                            }
                            {
                                sortedArticles
                                .filter((article) => (
                                    (helpers.replaceAccent(article.title).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase()) ||
                                    helpers.replaceAccent(article.description).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase()) ||
                                    article.members.some(member => helpers.replaceAccent(member.username).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase())))
                                    &&
                                    (year !== 'headerSelect' ? article.subcategories.some(subcategory => year === subcategory.title) : 'headerSelect')
                                    &&
                                    (theme !== 'headerSelect' ? article.subcategories.some(subcategory => theme === subcategory.title) : 'headerSelect')
                                )).length > paginationParams.articlesPerPage ?
                                <Pagination
                                    totalItems={
                                        sortedArticles
                                        .filter((article) => (
                                            (helpers.replaceAccent(article.title).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase()) ||
                                            helpers.replaceAccent(article.description).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase()) ||
                                            article.members.some(member => helpers.replaceAccent(member.username).toLowerCase().includes(helpers.replaceAccent(search).toString().toLowerCase())))
                                            &&
                                            (year !== 'headerSelect' ? article.subcategories.some(subcategory => year === subcategory.title) : 'headerSelect')
                                            &&
                                            (theme !== 'headerSelect' ? article.subcategories.some(subcategory => theme === subcategory.title) : 'headerSelect')
                                        )).length
                                        }
                                    itemsPerPage={paginationParams.articlesPerPage}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    color={categoryColor}
                                    /> : null
                            }
                        </>
                        }
            </div>
    )
}
