import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { axiosCaller } from '../../_services/axiosCaller';
import { updateAlertMessage } from '../../redux/redux';

export default function ForgottenPwd({forgottenPwdDisplayed, setForgottenPwdDisplayed, loginDisplayed, setLoginDisplayed}) {

    const [errors, setErrors] = useState({});

    const dispatchAlertMessage = useDispatch();
    const stockAlertMessageInStore = (data) => {
        dispatchAlertMessage(updateAlertMessage(data))
    }

    // Validation du formulaire et envoi des valeurs vers l'API
    const validForm = (e) => {
        e.preventDefault();
        let formValues = {};
        for (let item of e.target) {
            if (item.name !== '') {
                formValues[item.name] = item.value;
            }
        }
        axiosCaller.callAxiosDisconnect('/api/user/forgotten-password', 'POST', {
            "email": formValues.email
        })
        .then(response => {
            console.log(response)
            stockAlertMessageInStore({ type: 'success', content: 'Un mail contenant un lien de réinitialisation a été envoyé à l\'adresse ' + response.data.email + '. Pense à vérifier dans tes spams.' })
            setForgottenPwdDisplayed(false);
            setLoginDisplayed(true);
        })
        .catch(error => {
            console.log(error)
            stockAlertMessageInStore({ type: 'error', content: 'La réinitalisation n\'a pu aboutir, merci de corriger les erreurs.' })
            setErrors(error.response.data);
        });
    }

    return (
        <>
            <div className='forgottenPwd'>
                <p className='rule'>Saisis ton adresse email pour recevoir le lien de réinitalisation.</p>
                <form className='forgottenPwdForm' noValidate onSubmit={(e) => validForm(e)}>
                    <div className='formItem'>
                        <p className='fieldTitle'>Email</p>
                        <input
                            type="email"
                            id='email'
                            name='email'
                            className='field'
                            style={{ borderColor: errors && errors.email !== undefined ? "#ECACAC" : "#0c71c3" }}
                            placeholder='Entre ton adresse email'
                            keyboardtype='email-address'
                            autoCapitalize='none'
                        />
                        {
                            errors && errors.email !== undefined &&
                            <p className='errorItem'>{errors.email}</p>
                        }
                    </div>
                    <div className='actionBtns'>
                        <button className='cancelBtn' id='cancelBtn'
                            onClick={() => {
                                setForgottenPwdDisplayed(false);
                                setLoginDisplayed(true);
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="svgCancel" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                            </svg>
                            <span>Annuler</span>
                        </button>
                        <button type='submit' className='validateFormBtn' id='validateFormBtn'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svgValidate" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>
                            <span>Valider</span>
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}