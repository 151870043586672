import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function ArticleSkeleton() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    return (
        <SkeletonTheme baseColor="#F3F3F3" highlightColor="#ECEBEB">
            <div style={{
                width: '100%'
            }}>
                <Skeleton height={windowWidth / 3.2}/>
            </div>
            <div style={{
                backgroundColor: '#FAFAFA',
                width: '100%',
                height: window.matchMedia("(min-width: 940px)").matches ? 98 : 85,
                padding: '20px 100px 20px 10%',
            }}>
                <div style={{
                    
                }}>
                    <Skeleton height={window.matchMedia("(min-width: 940px)").matches ? 57 : 44}/>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#FAFAFA',
                width: 'calc(100% - 20px)',
                maxWidth: '1240px',
                borderRadius: '5px',
                padding: '25px',
                margin: '20px',
            }}>
                <div>
                    <div style={{
                        margin: '10px 0',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                        }}>
                        <Skeleton width={116} height={25} />
                    </div>
                    <div style={{
                        margin: '10px 0',
                        width: '100%',
                        }}>
                        <Skeleton height={32} />
                    </div>
                    <div style={{
                        margin: '10px 0',
                        width: '100%',
                        }}>
                        <Skeleton height={32} />
                    </div>
                    <div style={{
                        margin: '10px 0',
                        width: '100%',
                        }}>
                        <Skeleton height={44} />
                    </div>
                </div>
            </div>
            <div style={{
                    width: '90%',
                    marginBottom: '30px'
            }}>
                <Skeleton height={56}/>
            </div>
            <div style={{
                    width: '90%',
                    marginBottom: '30px'
            }}>
                <Skeleton height={56}/>
            </div>
        </SkeletonTheme>
    )
}
