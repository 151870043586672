import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateAlertMessage } from '../../redux/redux';
import { axiosCaller } from '../../_services/axiosCaller';
import { checkToken } from '../../_services/checkToken';
import { userServices } from '../../_services/user_services';
import CategoryTitleBand from '../../components/CategoryTitleBand';

export default function ModifyPassword() {

    document.title = 'La BAD | Modification mot passe';

    let location = useLocation();
    
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const dispatchAlertMessage = useDispatch();
    const stockAlertMessageInStore = (data) => {
        dispatchAlertMessage(updateAlertMessage(data))
    }

    useEffect(() => {
        window.scroll(0,0);
    }, [])

    // Validation du formulaire et envoi des valeurs vers l'API
    const validForm = (e) => {
        e.preventDefault();
        if (checkToken.expired()) {
            stockAlertMessageInStore({type: 'error', content: 'Ta session a expirée, merci de te reconnecter.'})
            userServices.logout();
            navigate('/');
        } else {
            let formValues = {};
            for (let item of e.target) {
                if (item.name !== '') {
                    formValues[item.name] = item.value;
                }
            }
/*             axiosCaller.askCsrf()
            .then((response) => { */
                axiosCaller.callAxios('/api/user/modify-password', 'POST'/* , response.data */, {
                    "actualPassword": formValues.actualPassword,
                    "password": formValues.password,
                    "passwordConfirm": formValues.passwordConfirm
                })
                .then(response => {
                    stockAlertMessageInStore({type: 'success', content: 'Ton mot de passe a été modifié avec succès.'})
                    navigate('/mon-compte');
                })
                .catch(error => {
                    stockAlertMessageInStore({type: 'error', content: 'La modification de ton mot de passe n\'a pu aboutir, merci de corriger les erreurs.'})
                    setErrors(error.response.data);
                });
            /* }) */
        }
    }

    const [actualPwdDisplayed, setActualPwdDisplayed] = useState();
    const [newPwdDisplayed, setNewPwdDisplayed] = useState();
    const [confirmPwdDisplayed, setConfirmPwdDisplayed] = useState();
    const [actualPassword, setActualPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const showActualPwd = () => {
        setActualPwdDisplayed(!actualPwdDisplayed);
    }

    const showNewPwd = () => {
        setNewPwdDisplayed(!newPwdDisplayed);
    }

    const showConfirmPwd = () => {
        setConfirmPwdDisplayed(!confirmPwdDisplayed);
    }

    return (
        <>
            <CategoryTitleBand slugCategory={location.pathname}/>
            <div className='modifyPassword'>
                <div className='pwdMessage'>
                        <p className='rulesTitle'>Règles de création de ton mot de passe : </p>
                        <ul id='pwdRules'>
                            <li>Entre 8 et 20 caractères</li>
                            <li>Au moins une lettre minuscule</li>
                            <li>Au moins une lettre majuscule</li>
                            <li>Au moins 1 chiffre</li>
                            <li>Au moins 1 caractère spécial (- + ! * $ @ % _)</li>
                        </ul>
                    </div>
                <form onSubmit={(e) => validForm(e)}>
                    <div className="infosGroup">
                        <div className='formItem'>
                            <label htmlFor="actualPassword">Mot de passe actuel</label>
                            <input type={actualPwdDisplayed ? "text" :"password"} id='actualPassword' name='actualPassword' style={{border: errors && errors.actualPassword !== undefined ? '1px solid #ECACAC' : ''}}/>
                            {
                                actualPwdDisplayed ?
                                    <svg onClick={(() => showActualPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                    </svg>
                                : 
                                    <svg onClick={(() => showActualPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                                    </svg>
                                
                            }
                            {
                                errors && errors.actualPassword !== undefined ?
                                <p className='errorItem'>{errors.actualPassword}</p> :
                                ''
                            }
                        </div>
                    </div>
                    <div className="infosGroup">
                        <div className='formItem newPwdItem'>
                            <label htmlFor="password">Nouveau mot de passe</label>
                            <input type={newPwdDisplayed ? "text" :"password"} id='password' name='password' style={{border: errors && errors.password !== undefined ? '1px solid #ECACAC' : ''}}/>
                            {
                                newPwdDisplayed ?
                                    <svg onClick={(() => showNewPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                    </svg>
                                : 
                                    <svg onClick={(() => showNewPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                                    </svg>
                                
                            }
                            {
                                errors && errors.password !== undefined ?
                                <p className='errorItem'>{errors.password}</p> :
                                ''
                            }
                        </div>
                        <div className='formItem confirmPwdItem'>
                            <label htmlFor="passwordConfirm">Confirmation du mot de passe</label>
                            <input type={confirmPwdDisplayed ? "text" :"password"} id='passwordConfirm' name='passwordConfirm' style={{border: errors && errors.passwordConfirm !== undefined ? '1px solid #ECACAC' : ''}}/>
                            {
                                confirmPwdDisplayed ?
                                    <svg onClick={(() => showConfirmPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                    </svg>
                                : 
                                    <svg onClick={(() => showConfirmPwd())} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00000099" className="eyeSvg" viewBox="0 0 16 16">
                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                                    </svg>
                                
                            }
                            {
                                errors && errors.passwordConfirm !== undefined ?
                                <p className='errorItem'>{errors.passwordConfirm}</p> :
                                ''
                            }
                        </div>
                    </div>
                    <div className="actionBtns">
                        <Button 
                            typeBtn='back'
                            btnSvg='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                </svg>'
                            btnTitle='Annuler'
                            btnUrl='/mon-compte'
                        />
                        <button type='submit' className='validateFormBtn'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            <span>Valider</span>
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}